/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import dayjs from "dayjs";

import {
    useGetMyProfileQuery,
    useUpdateProfileMutation,
} from "../../../services/userService";
import parseError from "../../../utils/errorUtils";

import { Form } from "../../../components/forms";
import UserDetailsForm from "./UserDetailsForm";

import "./appointment-page.scss";

const UserDetailsSchema = yup.object({
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter your last name"),
    dateOfBirth: yup
        .date()
        .required("Please enter your date of birth")
        .test(
            "dateOfBirth",
            "Should be at least 18 years old",
            function (value) {
                return dayjs(value).diff(new Date()) <= -567648000000;
            }
        ),
    gender: yup.string().required("Please select your gender"),
    phone: yup
        .string()
        .required("Please enter your mobile number")
        .matches("^[+]?[0-9]{8,15}$", "Please enter a valid mobile number"),
    address: yup.object().shape({
        postcode: yup
            .string()
            .required("Please select a postcode from the list"),
    }),
    preferences: yup.object().shape({
        shareDetailsWithGp: yup.bool(),
        accessNHSdetails: yup.bool().oneOf([true], "Please check to continue"),
        gpPractice: yup.string().when('shareDetailsWithGp', {
            is: true,
            then: yup.string().required("Please enter the practice name"),
        }),
        gpAddress: yup.object({
            postcode: yup.string().when('shareDetailsWithGp', {
                is: true,
                then: yup.string().required("Please enter the postcode"),
            }),
            line1: yup.string().when('shareDetailsWithGp', {
                is: true,
                then: yup.string().required("Please enter the address line 1"),
            }),
            city: yup.string().when('shareDetailsWithGp', {
                is: true,
                then: yup.string().required("Please enter the city"),
            }),
        }),
    }),
});

export default function UserDetailsPage() {
    const navigate = useNavigate();

    const [initValues] = useState({
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        gender: "",
        address: {
            postcode: "",
        },
        phone: "",
        preferences: {
            accessNHSdetails: false,
            shareDetailsWithGp: false,
            gpPractice: "",
            gpAddress: {
                postcode: "",
                line1: "",
                line2: "",
                city: "",
                county: "",
            },
        },
    });

    const [updateProfile] = useUpdateProfileMutation();
    const { data: profileData } = useGetMyProfileQuery();

    const handleSubmit = async (values) => {
        try {
            const data = {
                ...profileData,
                ...values,
                preferences: {
                    ...values.preferences,
                    accessNHSdetails: JSON.stringify(
                        !!values.preferences.accessNHSdetails
                    ),
                    shareDetailsWithGp: JSON.stringify(
                        !!values.preferences.shareDetailsWithGp
                    ),
                    // ignore the gpAddress attr. if the gp address is not available
                    ...(values.preferences.gpAddress
                        ? {
                              gpAddress: JSON.stringify(
                                  values.preferences.gpAddress
                              ),
                          }
                        : {}),
                },
                dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
                regStatus: "PERSONAL_INFORMATION",
            };

            const postcode = data.address?.postcode;

            if (!postcode) {
                toast.error(
                    "You must provide a valid post code to proceed with registration to Treat It; otherwise, you cannot continue"
                );
            } else if (postcode.slice(0, 2).toLowerCase().includes("bt")) {
                toast.error("Location not currently supported");
            } else {
                const res = await updateProfile({ values: data });

                if (res.error) {
                    // handle error
                    toast.error(
                        parseError(res.error, "Couldn't update profile")
                    );
                } else {
                    // request succeeded
                    navigate("/onboarding/appointment/select-slot");
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (
            profileData?.regStatus === "COMPLETE" &&
            profileData?.settings.IS_ONGOING_ORDER !== "true" &&
            profileData?.settings.INITIAL_CONSULTATION_DONE === "false"
        ) {
            navigate("/onboarding/appointment/select-slot");
        } else if (profileData?.regStatus === "COMPLETE") {
            navigate("/user/dashboard", { replace: true });
        }
    }, [profileData]);

    return (
        <div className="user-details-page px-3 m-auto pb-12">
            <div className="d-flex flex-column">
                <h2 className="text-primary fw-bold mb-5 text-center">
                    Personal details
                </h2>
                <p className="fs-5 subtitle text-primary-200 text-center mb-10 mx-2 mx-lg-8 px-lg-8">
                    Let's get to know you a bit better so we can schedule your
                    appointment.
                </p>

                <Form
                    initialValues={initValues}
                    onSubmit={handleSubmit}
                    validationSchema={UserDetailsSchema}
                    enableReinitialize
                >
                    <UserDetailsForm />
                </Form>
            </div>
        </div>
    );
}
